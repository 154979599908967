body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff!important;
}

/**
 * 滚动条
 */

::-webkit-scrollbar {
  width: 10px !important;
  height: 10px;
}
scrollbar {
  min-width: 10px !important;
  max-width: 10px !important;
}
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #d9d8d8;
}

li {
  list-style: none;
}

* {
  box-sizing: border-box;
}
.error-page {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
}
.error-image img{
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}
.error-image{
  width: 500px;
  height: 240px;
  position: absolute;
  top: 120px;
  left: 50%;
  transform: translateX(-50%);
}
.menu-logo{
  width: 150px;
  height: 24px;
  position: absolute;
  left: 56px;
  top: 40px;

}
.error-cont-desc{
  width: 412px;
  height: 28px;
  position: absolute;
  top: 404px;
  left: 50%;
  transform: translateX(-50%);
  font-weight: 400;
  color: #2D3446;
  line-height: 28px;
  font-size: 20px;
  text-align: center;
}
.error-cont-btn{
  width: 412px;
  height: 28px;
  position: absolute;
  top: 404px;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 40px;
  text-align: center;
}
.error-footer{
  position: absolute;
  left: 50%;
  bottom: 40px;
  transform: translateX(-50%);
  font-weight: 400;
  color: #677392;
  line-height: 24px;
  font-size: 14px;
  text-align: center;
}
.menu-logo img{
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}
